// play Musikwelle on click in status

import imgSpeaker from "../images/loudspeaker.png";
import imgSpeakerMuted from "../images/loudspeaker-muted.png";

document.querySelector(".sound").addEventListener("click", function () {
  const audio = document.querySelector("#sound-file");
  const speakerIcon = document.querySelector(".sound img");

  if (audio.paused) {
    audio.play();
    speakerIcon.src = imgSpeaker;
  } else {
    audio.pause();
    speakerIcon.src = imgSpeakerMuted;
  }
});
