import interact from "interactjs";

// open windows
const triggerLinks = document.querySelectorAll("a[data-window]");
const startMenu = document.querySelector("input#start-menu");
for (const link of triggerLinks) {
  link.addEventListener("click", function () {
    const windowSlug = this.dataset.window;
    const windowEl = document.querySelector("#" + windowSlug);

    windowEl.style.display = "block";
    startMenu.checked = false;
  });
}

const desktopLinks = document.querySelectorAll("[data-doubleclick-window]");
for (const link of desktopLinks) {
  link.addEventListener("dblclick", function () {
    const windowSlug = this.dataset.doubleclickWindow;
    const windowEl = document.querySelector("#" + windowSlug);

    windowEl.style.display = "block";
  });
  link.addEventListener("click", function () {
    this.classList.toggle("icon--active");
    startMenu.checked = false;
  });
}

// close windows
const closeButtons = document.querySelectorAll(".button--close");
for (const button of closeButtons) {
  button.addEventListener("click", function () {
    const windowEl = this.closest(".window");
    windowEl.style.display = "none";
  });
}

// draggable window bars
const windowBars = document.querySelectorAll(".title-bar");
const windows = document.querySelectorAll(".window");
for (const bar of windowBars) {
  let position = { x: 0, y: 0 };

  interact(bar).draggable({
    listeners: [
      {
        move(event) {
          position.x += event.dx;
          position.y += event.dy;
          event.target.closest(
            ".window"
          ).style.transform = `translate(${position.x}px, ${position.y}px)`;
        },
      },
      {
        start(event) {
          for (const window of windows) {
            window.classList.remove("window--active");
          }
          event.target.closest(".window").classList.add("window--active");
          event.target.closest(".window").classList.add("window--dragged");
        },
      },
      {
        end(event) {
          event.target.closest(".window").classList.remove("window--dragged");
        },
      },
    ],
    cursorChecker: (action, interactable, element, interacting) => {
      return interacting ? "grabbing" : "grab";
    },
  });
}

// resizable windows

for (const window of windows) {
  interact(window)
    .resizable({
      edges: {
        top: false, // Use pointer coords to check for resize.
        left: false, // Disable resizing from left edge.
        bottom: true, // Resize if pointer target matches selector
        right: true, // Resize if pointer target is the given Element
      },
      modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 250, height: 150 },
        }),
      ],
    })
    .on("resizemove", (event) => {
      let { x, y } = event.target.dataset;

      x = parseFloat(x) || 0;
      y = parseFloat(y) || 0;

      Object.assign(event.target.style, {
        width: `${event.rect.width}px`,
        height: `${event.rect.height}px`,
        transform: `translate(${event.deltaRect.left}px, ${event.deltaRect.top}px)`,
      });

      Object.assign(event.target.dataset, { x, y });
    });
}
